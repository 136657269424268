import React, { useEffect, useState } from "react";
import styles from "./Sourcing.module.css";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Checkbox,
  Box,
  Popover,
  Typography,
} from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import useJobsData from "../../../../api/useJobsData";
import { message } from "antd";
import {
  resetProfiles,
  setLoadingSkillCluster,
  setResetProfile,
} from "../../../../features/profileSlice/profileSlice";
import AddIcon from "@mui/icons-material/Add";
import LoginToSeeMore from "./LoginToSeeMore";
import {
  dataEngineerSkillClusterUpdation,
  dataScientistSkillClusterLabelUpdation,
  devopsEngineerSkillClusterLabelUpdation,
  dotNetDeveloperSkillClusterLabelUpdation,
  engineeringManagerSkillClusterLabelUpdation,
  flutterSkillClusterUpdation,
  iosDeveloperSkillClusterUpdation,
  javaDeveloperSkillClusterLabelUpdation,
  javaFullstackSkillClusterUpdation,
  linuxAdministratorSkillClusterUpdation,
  meanDeveloperSkillClusterLabelUpdation,
  mernStackSkillClusterLabelUpdation,
  networkEngineerSkillClusterUpdation,
  productDesignerSkillClusterLabelUpdation,
  productManagerSkillClusterLaelUpdation,
  pythonDeveloperSkillClusterUpdation,
  pythonFullstackSkillClusterUpdation,
  removeNegativeValues,
  rubyOnRailsSkillClusterUpdation,
  salesForceDeveloperSkillClusterUpdation,
  sharepointDeveloperSkillClusterLabelUpdation,
  testEngineerSkillClusterUpdation,
  uiDeveloperSkillClusterUpdation,
} from "./SourcingSkillClusterLabelFunction";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const Sourcing = ({ selectedLocation }) => {
  const {
    getCategory,
    getSkillCluster,
    getFreeProfiles,
    getProfiles,
    getViewedProfilesCount,
    profilesViewed,
    getViewedProfiles,
    getLocations,
  } = useJobsData();

  const skillClusterLoader = useSelector(
    (state) => state.profile.loadingSkillCluster
  );
  const categoriesLoading = useSelector(
    (state) => state.profile.loadingCategories
  );

  const [selectedFilter, setSelectedFilter] = useState(1);
  const [selectedDesignation, setSelectedDesignation] = useState("SDE");
  const [designations, setDesignations] = useState([]);
  const [skillClusters, setSkillCluster] = useState([]);
  const categories = useSelector((state) => state.profile.categories);
  const skillCluster = useSelector((state) => state.profile.skillCluster);

  const [selectedSkillClusters, setSelectedSkillClusters] = useState({
    label: "Java Spring html",
    value: "Java Spring html -API -mysql -AWS -Cloud -hibernate -kubernetes ",
  });
  const [javaProfileCount, setJavaPofileCount] = useState(0);
  const [isViewedProfilesSelected, setIsViewedProfilesSelected] =
    useState(false);
  const [dataScientistProfileCount, setDataScientistProfileCount] = useState(0);
  const [enginneringManagerProfileCount, setEngineeringManagerProfileCount] =
    useState(0);
  const [mernDeveloperProfilesCount, setMenrDeveloperProfilesCount] =
    useState(0);
  const [productManagerProfileCount, setProductManagerProfilesCount] =
    useState(0);

  const [dataEngineerProfileCount, setDataEngineerProfileCount] = useState(0);
  const [testEngineerProfileCount, setTestEngineerProfileCount] = useState(0);
  const [iosDeveloperProfileCount, setIOSDeveloperProfileCount] = useState(0);
  const [pythonFullstackProfileCount, setPythonFullstackProfileCount] =
    useState(0);
  const [javaFullstackProfileCount, setJavaFullstackProfileCount] = useState(0);
  const [salesForceDeveloperProfileCount, setSalesForceDeveloperProfileCount] =
    useState(0);
  const [flutterDeveloperProfileCount, setFlutterDeveloperProfileCount] =
    useState(0);

  const [networkEngineerProfileCount, setNetworkEngineerProfileCount] =
    useState(0);

  const [linuxAdministratorProfileCount, setLinuxAdministratorProfileCount] =
    useState(0);

  const [pythonDeveloperProfileCount, setPythonDeveloperProfileCount] =
    useState(0);

  const [uiDeveloperCount, setUIDeveloperCount] = useState(0);
  const [rubyOnRailsCount, setRubyOnRailsCount] = useState(0);
  const [productDesignerCount, setProductDesignerCount] = useState(0);
  const [meanDeveloperCount, setMeanDeveloperCount] = useState(0);
  const [devopsEngineerCount, setDevopsEngineerCount] = useState(0);
  const [sharepointDeveloperCount, setSharePointDeveloperCount] = useState(0);
  const [dotNetDeveloperCount, setDotNetDeveloperCount] = useState(0);

  const [visibleRoles, setVisibleRoles] = useState([
    { name: "Java Developer", id: 1, isVisible: true, show: true },
    { name: "Engineering Manager", id: 2, isVisible: true, show: true },
    { name: "Data Scientist", id: 3, isVisible: true, show: true },
    { name: "MERN Developer", id: 4, isVisible: false, show: true },
    { name: "Product Manager", id: 5, isVisible: false, show: true },
    { name: "Data Engineer", id: 6, isVisible: false, show: true },
    { name: "Test Engineer", id: 7, isVisible: false, show: true },
    { name: "iOS Developer", id: 8, isVisible: false, show: true },
    { name: "Salesforce Developer", id: 9, isVisible: false, show: true },
    { name: "Python FullStack", id: 10, isVisible: false, show: true },
    { name: "Java FullStack", id: 11, isVisible: false, show: true },
    { name: "Flutter Developer", id: 12, isVisible: false, show: true },
    { name: "Network Engineer", id: 13, isVisible: false, show: true },
    { name: "Linux Administrator", id: 14, isVisible: false, show: true },
    { name: "Python Developer", id: 15, isVisible: false, show: true },
    { name: "UI Developer", id: 16, isVisible: false, show: true },
    { name: "Ruby on Rails Developer", id: 17, isVisible: false, show: true },
    {
      name: "Product Designer/ UX Designer",
      id: 18,
      isVisible: false,
      show: true,
    },
    { name: "MEAN Developer", id: 19, isVisible: false, show: true },
    { name: "DevOps Engineer", id: 20, isVisible: false, show: true },
    { name: "Sharepoint Developer", id: 21, isVisible: false, show: true },
    { name: "Full Stack .NET Developer", id: 22, isVisible: false, show: true },
  ]);

  const [selectJobError, setSelectJobError] = useState(null);
  const [currentCandidates, setCurrentCandidates] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.auth2.userDetails);
  const candidates = useSelector((state) => state.profile.profiles);
  const loader = useSelector((state) => state.profile.loadingProfiles);
  const isAuth = useSelector((state) => state.auth2.isAuthorized);
  const locations = useSelector((state) => state.profile.locations);
  const isViewedProfileCount = useSelector(
    (state) => state.profile.isViewedProfilesCount
  );
  const isViewedProfileCountLoader = useSelector(
    (state) => state.profile.profileCountLoader
  );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const componentRef = React.useRef(null);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const candidatesPerPage = 20;
  const totalProfilePages = useSelector((state) => state.profile.totalPages);
  const totalPages = isAuth
    ? totalProfilePages
    : Math.ceil(candidates?.length / candidatesPerPage);

  useEffect(() => {
    if (selectedLocation) {
      if (selectedLocation !== "Karnataka, India") {
        setSelectedDesignation("");
        setSelectedSkillClusters(null);
      }
      setIsViewedProfilesSelected(false);
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (locations.length > 0) {
      setSelectedDesignation("SDE");
      const jobRole = selectedRoleHelperFunc(selectedFilter);
      getSkillCluster(jobRole, "SDE", selectedLocation);
      if (isAuth) {
        getViewedProfilesCount(
          "Java Developer",
          selectedDesignation,
          "Java Spring html -API -mysql -AWS -Cloud -hibernate -kubernetes ",
          1
        );

        getProfiles(
          "Java Developer",
          selectedDesignation,
          "Java Spring html -API -mysql -AWS -Cloud -hibernate -kubernetes ",
          "Karnataka, India",
          1
        );
      } else {
        getFreeProfiles(
          "Java Developer",
          selectedDesignation,
          "Java Spring html -API -mysql -AWS -Cloud -hibernate -kubernetes ",
          "Karnataka, India"
        );
      }
    }
  }, [locations]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    } else {
      setCurrentPage(newPage);
    }

    // Scroll to the top of the component
    if (componentRef.current) {
      componentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const selectedRoleHelperFunc = (value) => {
    let selectedRole =
      value === 1
        ? "Java Developer"
        : value === 2
        ? "Engineering Manager"
        : value === 3
        ? "Data Scientist"
        : value === 4
        ? "MERN Developer"
        : value === 5
        ? "Product Manager"
        : value === 6
        ? "Data Engineer"
        : value === 7
        ? "Test Engineer"
        : value === 8
        ? "iOS Developer"
        : value === 9
        ? "Salesforce Developer"
        : value === 10
        ? "Python FullStack"
        : value === 11
        ? "Java FullStack"
        : value === 12
        ? "Flutter Developer"
        : value === 13
        ? "Network Engineer"
        : value === 14
        ? "Linux Administrator"
        : value === 15
        ? "Python Developer"
        : value === 16
        ? "UI Developer"
        : value === 17
        ? "Ruby on Rails Developer"
        : value === 18
        ? "Product Designer/ UX Designer"
        : value === 19
        ? "MEAN Developer"
        : value === 20
        ? "DevOps Engineer"
        : value === 21
        ? "Sharepoint Developer"
        : value === 22
        ? "Full Stack .NET Developer"
        : "";

    return selectedRole;
  };

  const startIdx = (currentPage - 1) * candidatesPerPage;

  useEffect(() => {
    if (candidates?.length > 0 && !isAuth) {
      const currentCandidates = candidates.slice(
        startIdx,
        startIdx + candidatesPerPage
      );
      setCurrentCandidates(currentCandidates);
    } else {
      setCurrentCandidates(candidates);
    }
  }, [candidates, currentPage]);

  useEffect(() => {
    setVisibleRoles((prevRoles) =>
      prevRoles
        .map((role) => {
          const matchingCategory = categories.find(
            (category) => category.category === role.name
          );

          if (!matchingCategory || matchingCategory.total_candidates === 0) {
            return { ...role, show: false, isVisible: false };
          }

          if (
            role.name === "Java Developer" ||
            role.name === "Data Scientist" ||
            role.name === "Engineering Manager"
          ) {
            return { ...role, show: true, isVisible: true };
          }

          return { ...role, show: true, isVisible: false };
        })
        .sort((a, b) => a.id - b.id)
    );

    setSelectedFilter(1);
  }, [categories]);

  useEffect(() => {
    // Update counts for each role
    categories.forEach((category) => {
      if (category.category === "Java Developer") {
        setJavaPofileCount(category.total_candidates);
      }
      if (category.category === "Engineering Manager") {
        setEngineeringManagerProfileCount(category.total_candidates);
      }
      if (category.category === "Data Scientist") {
        setDataScientistProfileCount(category.total_candidates);
      }
      if (category.category === "MERN Developer") {
        setMenrDeveloperProfilesCount(category.total_candidates);
      }
      if (category.category === "Product Manager") {
        setProductManagerProfilesCount(category.total_candidates);
      }
      if (category.category === "Data Engineer") {
        setDataEngineerProfileCount(category.total_candidates);
      }
      if (category.category === "Test Engineer") {
        setTestEngineerProfileCount(category.total_candidates);
      }
      if (category.category === "iOS Developer") {
        setIOSDeveloperProfileCount(category.total_candidates);
      }
      if (category.category === "Salesforce Developer") {
        setSalesForceDeveloperProfileCount(category.total_candidates);
      }
      if (category.category === "Python FullStack") {
        setPythonFullstackProfileCount(category.total_candidates);
      }
      if (category.category === "Java FullStack") {
        setJavaFullstackProfileCount(category.total_candidates);
      }
      if (category.category === "Flutter Developer") {
        setFlutterDeveloperProfileCount(category.total_candidates);
      }
      if (category.category === "Network Engineer") {
        setNetworkEngineerProfileCount(category.total_candidates);
      }
      if (category.category === "Linux Administrator") {
        setLinuxAdministratorProfileCount(category.total_candidates);
      }
      if (category.category === "Python Developer") {
        setPythonDeveloperProfileCount(category.total_candidates);
      }
      if (category.category === "UI Developer") {
        setUIDeveloperCount(category.total_candidates);
      }
      if (category.category === "Ruby on Rails Developer") {
        setRubyOnRailsCount(category.total_candidates);
      }
      if (category.category === "Product Designer/ UX Designer") {
        setProductDesignerCount(category.total_candidates);
      }
      if (category.category === "MEAN Developer") {
        setMeanDeveloperCount(category.total_candidates);
      }
      if (category.category === "DevOps Engineer") {
        setDevopsEngineerCount(category.total_candidates);
      }
      if (category.category === "Sharepoint Developer") {
        setSharePointDeveloperCount(category.total_candidates);
      }
      if (category.category === "Full Stack .NET Developer") {
        setDotNetDeveloperCount(category.total_candidates);
      }
    });

    // Filtering designations for Java Developer only
    categories.forEach((category) => {
      if (category.category === "Java Developer") {
        setDesignations(category.tags);
      }
    });
  }, [categories]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const randomIndex = Math.floor(Math.random() * (i + 1));
      [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
    }
    return array;
  }

  useEffect(() => {
    if (skillCluster?.length > 0) {
      const result = skillCluster.map((item) => {
        // Split the string into words
        if (item === null) {
          return { label: "others", value: "" };
        }

        if (selectedFilter === 1) {
          return javaDeveloperSkillClusterLabelUpdation(item);
        }

        if (selectedFilter === 2) {
          return engineeringManagerSkillClusterLabelUpdation(item);
        }

        if (selectedFilter === 3) {
          return dataScientistSkillClusterLabelUpdation(item);
        }

        if (selectedFilter === 4) {
          return mernStackSkillClusterLabelUpdation(item);
        }

        if (selectedFilter === 5) {
          return productManagerSkillClusterLaelUpdation(item);
        }

        if (selectedFilter === 6) {
          return dataEngineerSkillClusterUpdation(item);
        }

        if (selectedFilter === 7) {
          return testEngineerSkillClusterUpdation(item);
        }

        if (selectedFilter === 8) {
          return iosDeveloperSkillClusterUpdation(item);
        }

        if (selectedFilter === 9) {
          return salesForceDeveloperSkillClusterUpdation(item);
        }

        if (selectedFilter === 10) {
          return pythonFullstackSkillClusterUpdation(item);
        }

        if (selectedFilter === 11) {
          return javaFullstackSkillClusterUpdation(item);
        }

        if (selectedFilter === 12) {
          return flutterSkillClusterUpdation(item);
        }

        if (selectedFilter === 13) {
          return networkEngineerSkillClusterUpdation(item);
        }

        if (selectedFilter === 14) {
          return linuxAdministratorSkillClusterUpdation(item);
        }

        if (selectedFilter === 15) {
          return pythonDeveloperSkillClusterUpdation(item);
        }

        if (selectedFilter === 16) {
          return uiDeveloperSkillClusterUpdation(item);
        }

        if (selectedFilter === 17) {
          return rubyOnRailsSkillClusterUpdation(item);
        }

        if (selectedFilter === 18) {
          return productDesignerSkillClusterLabelUpdation(item);
        }

        if (selectedFilter === 19) {
          return meanDeveloperSkillClusterLabelUpdation(item);
        }

        if (selectedFilter === 20) {
          return devopsEngineerSkillClusterLabelUpdation(item);
        }

        if (selectedFilter === 21) {
          return sharepointDeveloperSkillClusterLabelUpdation(item);
        }
        if (selectedFilter === 22) {
          return dotNetDeveloperSkillClusterLabelUpdation(item);
        } else {
          let label = removeNegativeValues(item);

          return {
            label: label || "Others",
            value: item,
          };
        }
      });

      const shuffledResult = shuffleArray(result);
      const others = shuffledResult.filter((item) => item.label === "others");
      const rest = shuffledResult.filter((item) => item.label !== "others");

      const reorderedResult = [...rest, ...others];
      const mergedAllOthersValue = reorderedResult.reduce((acc, item) => {
        if (item.label === "others") {
          // If the label is "others", create or update an array of skills
          const existing = acc.find((entry) => entry.label === "others");
          if (existing) {
            // Add the current item's value to the existing array
            existing.value = [...existing.value, item.value];
          } else {
            // Initialize the "others" entry with an array containing the current value
            acc.push({ label: "others", value: [item.value] });
          }
        } else {
          acc.push(item);
        }
        return acc;
      }, []);

      setSkillCluster(mergedAllOthersValue);
    }
  }, [skillCluster]);

  useEffect(() => {
    // getLocations();
    // dispatch(setLoadingSkillCluster(false));
    if (isAuth) {
      getViewedProfilesCount("Java Developer", null, null);
    }
  }, []);

  const handleDesignation = (e) => {
    e.preventDefault();
    setSelectedSkillClusters(null);
    setIsViewedProfilesSelected(false);
    const category = e.target.value;
    setSelectedDesignation(category);
    setCurrentPage(1);
    const jobRole = selectedRoleHelperFunc(selectedFilter);
    getSkillCluster(jobRole, category, selectedLocation);
    if (isAuth) {
      getViewedProfilesCount(jobRole, category, null);
    }
  };

  const handleSkillClusterChange = (event) => {
    const value = event.target.value;

    // Find the selected skill object based on the value
    const selectedSkill = skillClusters.find((skill) => skill.value === value);
    setIsViewedProfilesSelected(false);

    setSelectedSkillClusters(selectedSkill); // Store the single selected skill
    if (selectedSkill) {
      const skillClusterString = selectedSkill.value;

      const selectedFilterName = selectedRoleHelperFunc(selectedFilter);

      setCurrentPage(1);
      dispatch(setResetProfile());
      // Fetch profiles based on the selected skill cluster
      if (!isAuth) {
        getFreeProfiles(
          selectedFilterName,
          selectedDesignation,
          skillClusterString,
          selectedLocation
        );
      } else {
        getViewedProfilesCount(
          selectedFilterName,
          selectedDesignation,
          skillClusterString,
          1
        );

        getProfiles(
          selectedFilterName,
          selectedDesignation,
          skillClusterString,
          selectedLocation,
          1
        );
      }
    }
  };

  const handleViewAndLinkedin = (url, id) => {
    if (isAuth) {
      let selectedCategory = selectedRoleHelperFunc(selectedFilter);
      const skillClusterString = selectedSkillClusters.value;
      profilesViewed(
        id,
        selectedCategory,
        selectedDesignation,
        skillClusterString
      );
    }
    window.open(url, "_blank");
  };

  const handleFilter = (selectedValue) => {
    if (selectedFilter === selectedValue) {
      return;
    } else {
      dispatch(resetProfiles());
      setIsViewedProfilesSelected(false);
      setSelectedFilter(selectedValue);
      setDesignations([]);
      setSelectedSkillClusters({});
      setSelectedDesignation();
      setSkillCluster([]);
      setSelectedDesignation("");
      let selectedCategory = selectedRoleHelperFunc(selectedValue);
      if (isAuth) {
        getViewedProfilesCount(selectedCategory);
      }
      categories.map((category) => {
        if (category.category === selectedCategory) {
          if (selectedCategory === "iOS Developer") {
            let filteredCategories = category.tags.filter(
              (data) => data !== "ios engineer"
            );
            setDesignations(filteredCategories);
          } else {
            setDesignations(category.tags);
          }
        }
      });
    }
  };

  const handlePagination = (newPage) => {
    if (componentRef.current) {
      componentRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    let selectedCategory = selectedRoleHelperFunc(selectedFilter);
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
      const skillClusterString = selectedSkillClusters.value;
      getProfiles(
        selectedCategory,
        selectedDesignation,
        skillClusterString,
        selectedLocation,
        newPage
      );
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    const visibleCount = visibleRoles.filter(
      (designation) => designation.isVisible
    ).length;
    if (visibleCount < 3) {
      message.error("You must select at least 3 designations.");
      return; // Prevent closing if the condition is not met
    }
    setAnchorEl(null); // Proceed to close
  };

  const handleSelectRole = (id) => {
    setVisibleRoles((prevState) => {
      // Get all currently visible roles
      const visibleRoles = prevState.filter(
        (designation) => designation.isVisible
      );

      // Check if the clicked role is already selected
      const clickedRole = prevState.find(
        (designation) => designation.id === id
      );

      if (clickedRole.isVisible) {
        // If the clicked role is already visible, deselect it
        setSelectJobError(null);
        return prevState.map((designation) =>
          designation.id === id
            ? { ...designation, isVisible: false }
            : designation
        );
      }

      // If there are already 3 visible roles, remove the topmost one
      let updatedRoles = [...prevState];
      if (visibleRoles.length >= 3) {
        const topRoleId = visibleRoles[0].id; // Topmost selected role
        updatedRoles = updatedRoles.map((designation) =>
          designation.id === topRoleId
            ? { ...designation, isVisible: false } // Unselect topmost role
            : designation
        );
      }

      // Add the newly clicked role as selected
      updatedRoles = updatedRoles.map((designation) =>
        designation.id === id
          ? { ...designation, isVisible: true } // Select the clicked role
          : designation
      );

      setSelectJobError(null); // Reset error

      // Call filter and analytics functions
      handleFilter(id);
      if (isAuth) {
        getViewedProfilesCount(clickedRole.name, null, null);
      }

      // Sort to show selected roles on top
      return updatedRoles.sort((a, b) => {
        if (a.isVisible === b.isVisible) return 0; // Keep order if visibility is the same
        return a.isVisible ? -1 : 1; // Move selected roles to the top
      });
    });
  };

  const handleViewedProfiles = () => {
    const selectedFilterName = selectedRoleHelperFunc(selectedFilter);
    const skillClusterString =
      selectedSkillClusters === null ? null : selectedSkillClusters.value;
    const designation = selectedDesignation === "" ? null : selectedDesignation;
    if (!isViewedProfilesSelected) {
      getViewedProfiles(selectedFilterName, designation, skillClusterString, 1);
    } else {
      if (selectedDesignation && selectedSkillClusters) {
        getProfiles(
          selectedFilterName,
          selectedDesignation,
          skillClusterString,
          selectedLocation,
          1
        );
      }
    }
    setCurrentPage(1);
    setIsViewedProfilesSelected(!isViewedProfilesSelected);
  };

  const computeCenterRoleId = (roles) => {
    const visibleRoles = roles.filter((role) => role.isVisible);

    // Return null if only one role is visible
    if (visibleRoles.length === 1) {
      return null;
    }

    // Calculate the center index for multiple visible roles
    const centerIndex = Math.floor(visibleRoles.length / 2);
    return visibleRoles[centerIndex]?.id; // Get the ID of the center role
  };

  const refferCodeCopy = () => {
    navigator.clipboard
      .writeText(userDetails.referralCode)
      .then(() => {
        message.success("Referral Code copied to clipboard");
      })
      .catch(() => {
        message.error("Failed to copy Referral Code");
      });
  };

  const centerRoleId = computeCenterRoleId(visibleRoles);

  return (
    <div ref={componentRef}>
      <div className={styles.loadingSkillCLuster}></div>
      <div className={styles.mainSourcingContainer}>
        <div className={styles.topBar}>
          <div className={styles.designationBar}>
            <div className={styles.designationBarOptions}>
              {visibleRoles.map(
                (role) =>
                  role.isVisible && (
                    <div
                      className={styles.candidateType}
                      key={role.id}
                      onClick={() => {
                        handleFilter(role.id);
                      }}
                      style={{
                        width: role.id === centerRoleId ? "60%" : "", // Set 60% for center role
                      }}
                    >
                      <span>
                        {role.id === 1
                          ? javaProfileCount
                          : role.id === 2
                          ? enginneringManagerProfileCount
                          : role.id === 3
                          ? dataScientistProfileCount
                          : role.id === 4
                          ? mernDeveloperProfilesCount
                          : role.id === 5
                          ? productManagerProfileCount
                          : role.id === 6
                          ? dataEngineerProfileCount
                          : role.id === 7
                          ? testEngineerProfileCount
                          : role.id === 8
                          ? iosDeveloperProfileCount
                          : role.id === 9
                          ? salesForceDeveloperProfileCount
                          : role.id === 10
                          ? pythonFullstackProfileCount
                          : role.id === 11
                          ? javaFullstackProfileCount
                          : role.id === 12
                          ? flutterDeveloperProfileCount
                          : role.id === 13
                          ? networkEngineerProfileCount
                          : role.id === 14
                          ? linuxAdministratorProfileCount
                          : role.id === 15
                          ? pythonDeveloperProfileCount
                          : role.id === 16
                          ? uiDeveloperCount
                          : role.id === 17
                          ? rubyOnRailsCount
                          : role.id === 18
                          ? productDesignerCount
                          : role.id === 19
                          ? meanDeveloperCount
                          : role.id === 20
                          ? devopsEngineerCount
                          : role.id === 21
                          ? sharepointDeveloperCount
                          : role.id === 22
                          ? dotNetDeveloperCount
                          : ""}{" "}
                        CANDIDATES
                      </span>
                      <h3
                        style={{
                          textUnderlineOffset: "0.5rem",
                          textDecoration: `${
                            selectedFilter === role.id ? "underline" : ""
                          }`,
                        }}
                      >
                        {role.name}
                      </h3>
                    </div>
                  )
              )}
            </div>
            <div
              className={styles.viewMoreAnim}
              aria-describedby={id}
              onClick={handleClick}
              style={{
                cursor: "pointer",
                display: "flex",
                gap: "0.05rem",
                fontWeight: "500",
                animation: `${open ? "none" : ""}`,
              }}
            >
              View More <AddIcon />
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              PaperProps={{
                sx: {
                  maxHeight: "330px", // Set the desired height here
                  overflowY: "auto", // Add scroll if content overflows
                },
              }}
            >
              {visibleRoles.map(
                (dropDownDesignations, index) =>
                  dropDownDesignations.show && (
                    <Box
                      style={{ display: "flex", flexDirection: "column" }}
                      key={index}
                    >
                      <Typography
                        sx={{
                          py: 1.5,
                          px: 1,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          handleSelectRole(dropDownDesignations.id)
                        }
                      >
                        <Checkbox
                          checked={dropDownDesignations.isVisible}
                          sx={{
                            color: "#f99c38",
                            "&.Mui-checked": {
                              color: "#f99c38",
                            },
                          }}
                        />
                        {dropDownDesignations.name}
                      </Typography>
                      {selectJobError == dropDownDesignations.id && (
                        <span
                          style={{
                            color: "red",
                            fontSize: "13px",
                            width: "90%",
                            paddingLeft: "1.25rem",
                            fontWeight: "500",
                          }}
                        >
                          Alert: Unselect any one role
                        </span>
                      )}
                    </Box>
                  )
              )}
              <Typography
                sx={{
                  pt: 0,
                  pb: 2,
                  px: 3,
                  fontWeight: "500",
                  cursor: "pointer",
                  width: "240px",
                  fontSize: "14px",
                  color: "#808080",
                }}
              >
                Unselect any role before selecting a new one.
              </Typography>
            </Popover>
          </div>

          <div className={styles.filterBar}>
            {selectedFilter !== 0 && (
              <div className={styles.dropDownContainer}>
                {selectedLocation && (
                  <FormControl
                    sx={{ minWidth: "30%", position: "relative" }}
                    disabled={categoriesLoading || designations?.length === 0}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      sx={{
                        color: "#5D5D5D", // Default label color
                        "&.Mui-focused": {
                          color: "#191919",
                        },
                      }}
                    >
                      Designation
                    </InputLabel>
                    <Select
                      sx={{
                        bgcolor: "#f8e9d5", // Background color for the select dropdown
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#191919", // Border color
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#191919", // Border color on hover
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#191919", // Border color when focused
                        },
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Designation"
                      value={selectedDesignation} // Control the value with state
                      onChange={handleDesignation} // Handle selection changes
                    >
                      {designations.map((data, index) => (
                        <MenuItem value={data} key={index}>
                          {data}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {!categoriesLoading &&
                  selectedDesignation !== "" &&
                  (skillClusterLoader ? (
                    <div
                      style={{
                        backgroundColor: "#f8e9d5",
                        width: "250px",
                        padding: "6px 10px",
                        borderRadius: "8px",
                        border: "1.5px solid #000",
                      }}
                    >
                      <Skeleton width={"100%"} height={40}></Skeleton>
                    </div>
                  ) : (
                    <FormControl
                      sx={{
                        minWidth: "40%",
                        maxWidth: "auto",
                        position: "relative",
                      }}
                      disabled={
                        skillClusterLoader || skillClusters?.length === 0
                      } // Disable the entire FormControl when loading
                    >
                      <InputLabel
                        id="demo-single-name-label"
                        sx={{
                          color: "#5D5D5D",
                          "&.Mui-focused": {
                            color: "#191919",
                          },
                        }}
                      >
                        Skill Cluster
                      </InputLabel>
                      <Select
                        labelId="demo-single-name-label"
                        id="demo-single-name"
                        value={selectedSkillClusters?.value || ""} // Use value of the selected object
                        onChange={handleSkillClusterChange}
                        input={
                          <OutlinedInput
                            id="select-single-name"
                            label="Skill Cluster"
                          />
                        }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 300,
                              minWidth: 250,
                            },
                          },
                        }}
                        sx={{
                          bgcolor: "#f8e9d5",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#191919",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#191919",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#191919",
                          },
                        }}
                      >
                        {skillClusters.map((skill, index) => (
                          <MenuItem
                            key={index}
                            value={skill.value} // Value should be the `value` of the skill
                            sx={{
                              whiteSpace: "normal",
                              display: "block",
                              wordBreak: "break-word",
                              fontWeight:
                                selectedSkillClusters?.value === skill.value
                                  ? "bold"
                                  : "normal",
                              bgcolor:
                                selectedSkillClusters?.value === skill.value
                                  ? "#f8e9d5"
                                  : "",
                            }}
                          >
                            {skill.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ))}
              </div>
            )}

            {/* {isAuth &&
              (isViewedProfileCountLoader ? (
                <div className={styles.skeletonLoaderIsViewed}>
                  <div className={styles.skeletonIcon}></div>
                  <div className={styles.skeletonText}></div>
                </div>
              ) : (
                <div
                  className={styles.countContainer}
                  onClick={() => handleViewedProfiles()}
                  style={{
                    backgroundColor: `${
                      isViewedProfilesSelected ? "#F8E9D5" : ""
                    }`,
                  }}
                >
                  <VisibilityIcon />
                  <span>{isViewedProfileCount}</span>
                </div>
              ))} */}
          </div>
        </div>

        {userDetails.referralCode && (
          <div className={styles.refferalCodeText}>
            You won a Referral code to invite two Recruiters like you -{" "}
            <span onClick={refferCodeCopy}>
              <ContentCopyIcon style={{ paddingRight: "5px" }} />
              {userDetails.referralCode}
            </span>{" "}
            (Valid for 2 users only)
          </div>
        )}

        <div className={styles.sourcingContainer}>
          {isViewedProfilesSelected ||
          (selectedFilter !== 0 &&
            selectedDesignation !== "" &&
            selectedSkillClusters !== null) ? (
            <div className={styles.candidateList}>
              {loader || categoriesLoading ? (
                Array.from({ length: 20 }).map((_, index) => (
                  <div className={styles.skeletonLoader}>
                    <div className={styles.skeletonInfo}>
                      <Skeleton variant="circular" width={58} height={58} />
                      <div className={styles.infoConainerSkeleton}>
                        <Skeleton
                          variant="rectangular"
                          width={"25%"}
                          height={30}
                        />
                        <div className={styles.posotionSkeleton}>
                          <Skeleton
                            variant="rectangular"
                            width={"23%"}
                            height={23}
                          />

                          <Skeleton
                            variant="rectangular"
                            width={"18%"}
                            height={23}
                          />
                        </div>
                        <Skeleton
                          variant="rectangular"
                          width={"20%"}
                          height={23}
                        />

                        <Skeleton
                          variant="rectangular"
                          width={"95%"}
                          height={40}
                        />
                        <Skeleton
                          variant="rectangular"
                          width={"80%"}
                          height={23}
                        />
                      </div>
                    </div>
                    <Skeleton
                      variant="rectangular"
                      width={"13%"}
                      height={40}
                      style={{ borderRadius: "20px" }}
                    />
                  </div>
                ))
              ) : (
                <>
                  {!isAuth && currentPage === 4 ? (
                    <LoginToSeeMore page={4} />
                  ) : !isAuth &&
                    currentPage === 3 &&
                    candidates &&
                    candidates?.length > 0 ? (
                    <>
                      {currentCandidates?.map((candidate, index) => (
                        <div
                          key={index}
                          className={`${styles.candidateCard} ${styles.fadeIn}`}
                          style={{
                            borderBottom: `${
                              candidates?.length - 1 === index ? "none" : ""
                            }`,
                          }}
                        >
                          <div className={styles.profileInfo}>
                            {candidate.profile_picture_path !== null ? (
                              <img
                                src={candidate.profile_picture_path}
                                alt={candidate.name}
                                className={styles.profilePicture}
                              />
                            ) : (
                              <div className={styles.noCandidatePic}></div>
                            )}
                            <div className={styles.candidateInfo}>
                              <div className={styles.candidateName}>
                                {candidate.name}
                              </div>
                              <div className={styles.candidateRole}>
                                {candidate.occupation}
                              </div>
                              <div className={styles.candidateLocation}>
                                {candidate.location}
                              </div>
                            </div>
                          </div>
                          <button
                            className={styles.connectButton}
                            onClick={() =>
                              handleViewAndLinkedin(
                                candidate.linkedin,
                                candidate.id
                              )
                            }
                          >
                            Connect
                          </button>
                        </div>
                      ))}
                      <LoginToSeeMore page={3} />
                    </>
                  ) : candidates && candidates?.length > 0 ? (
                    currentCandidates?.map((candidate, index) => (
                      <div
                        key={index}
                        className={`${styles.candidateCard} ${styles.fadeIn}`}
                        style={{
                          borderBottom: `${
                            candidates?.length - 1 === index ? "none" : ""
                          }`,
                        }}
                      >
                        <div className={styles.profileInfo}>
                          {candidate.profile_picture_path !== null ? (
                            <img
                              src={candidate.profile_picture_path}
                              alt={candidate.name}
                              className={styles.profilePicture}
                            />
                          ) : (
                            <div className={styles.noCandidatePic}></div>
                          )}
                          <div className={styles.candidateInfo}>
                            <div className={styles.candidateName}>
                              {candidate.name}
                            </div>
                            <div className={styles.candidateRole}>
                              {candidate.occupation}
                            </div>
                            <div className={styles.candidateLocation}>
                              {candidate.location}
                            </div>
                          </div>
                        </div>
                        <button
                          className={styles.connectButton}
                          onClick={() =>
                            handleViewAndLinkedin(
                              candidate.linkedin,
                              candidate.id
                            )
                          }
                        >
                          Connect
                        </button>
                      </div>
                    ))
                  ) : (
                    <div className={styles.selectDesigMessage}>
                      No Profiles found for selected filters
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            !isViewedProfilesSelected &&
            (selectedFilter === 0 ? (
              <div className={styles.selectDesigMessage}>
                Select Job Profile to load profiles
              </div>
            ) : selectedDesignation !== "" ? (
              <div className={styles.selectDesigMessage}>
                Select Skill Cluster to load profiles
              </div>
            ) : (
              <div className={styles.selectDesigMessage}>
                Select Designation to load profiles
              </div>
            ))
          )}
        </div>

        {isAuth
          ? selectedDesignation !== "" &&
            selectedSkillClusters !== null && (
              <div
                className={styles.sourcingContainer}
                style={{ background: "transparent" }}
              >
                {candidates?.length > 0 && (
                  <div className={styles.paginationContainer}>
                    <button
                      className={`${styles.paginationButton} ${
                        currentPage === 1 ? styles.paginationButtonDisabled : ""
                      }`}
                      disabled={currentPage === 1}
                      onClick={() => handlePagination(currentPage - 1)}
                    >
                      Prev
                    </button>

                    <div className={styles.paginationButtons}>
                      {totalPages <= 5 ? (
                        // Display all pages if total pages are 5 or fewer
                        Array.from({ length: totalPages }).map((_, index) => (
                          <button
                            key={index}
                            className={`${styles.paginationButton} ${
                              currentPage === index + 1 ? styles.activePage : ""
                            }`}
                            onClick={() => handlePagination(index + 1)}
                          >
                            {index + 1}
                          </button>
                        ))
                      ) : (
                        <>
                          {currentPage > 3 && (
                            <>
                              <span className={styles.paginationEllipsis}>
                                ...
                              </span>
                            </>
                          )}

                          {/* Display dynamic page range */}
                          {Array.from({ length: 5 }).map((_, index) => {
                            const page =
                              currentPage <= 3
                                ? index + 1
                                : Math.min(
                                    currentPage - 2 + index,
                                    totalPages - 5 + index + 1
                                  );
                            return (
                              <button
                                key={page}
                                className={`${styles.paginationButton} ${
                                  currentPage === page ? styles.activePage : ""
                                }`}
                                onClick={() => handlePagination(page)}
                              >
                                {page}
                              </button>
                            );
                          })}

                          {currentPage < totalPages - 2 && (
                            <>
                              <span className={styles.paginationEllipsis}>
                                ...
                              </span>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <button
                      className={`${styles.paginationButton} ${
                        currentPage === totalPages
                          ? styles.paginationButtonDisabled
                          : ""
                      }`}
                      disabled={currentPage === totalPages}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            )
          : selectedDesignation !== "" &&
            !categoriesLoading &&
            selectedSkillClusters !== null && (
              <div
                className={styles.sourcingContainer}
                style={{ background: "transparent" }}
              >
                {candidates?.length > 0 && (
                  <div className={styles.paginationContainer}>
                    <button
                      className={`${styles.paginationButton} ${
                        currentPage === 1 ? styles.paginationButtonDisabled : ""
                      }`}
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Prev
                    </button>

                    <div className={styles.paginationButtons}>
                      {totalPages <= 5 ? (
                        // Display all pages if total pages are 5 or fewer
                        Array.from({ length: totalPages + 1 }).map(
                          (_, index) => (
                            <button
                              key={index}
                              className={`${styles.paginationButton} ${
                                currentPage === index + 1
                                  ? styles.activePage
                                  : ""
                              }`}
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </button>
                          )
                        )
                      ) : (
                        <>
                          {currentPage > 3 && (
                            <>
                              <button
                                className={styles.paginationButton}
                                onClick={() => handlePageChange(1)}
                              >
                                1
                              </button>
                              <span className={styles.paginationEllipsis}>
                                ...
                              </span>
                            </>
                          )}

                          {/* Display dynamic page range */}
                          {Array.from({ length: 5 }).map((_, index) => {
                            const page =
                              currentPage <= 3
                                ? index + 1
                                : Math.min(
                                    currentPage - 2 + index,
                                    totalPages - 5 + index + 1
                                  );
                            return (
                              <button
                                key={page}
                                className={`${styles.paginationButton} ${
                                  currentPage === page ? styles.activePage : ""
                                }`}
                                onClick={() => handlePageChange(page)}
                              >
                                {page}
                              </button>
                            );
                          })}

                          {currentPage < totalPages - 2 && (
                            <>
                              <span className={styles.paginationEllipsis}>
                                ...
                              </span>
                              <button
                                className={styles.paginationButton}
                                onClick={() => handlePageChange(totalPages)}
                              >
                                {totalPages}
                              </button>
                            </>
                          )}
                        </>
                      )}
                    </div>

                    <button
                      className={`${styles.paginationButton} ${
                        currentPage === totalPages + 1
                          ? styles.paginationButtonDisabled
                          : ""
                      }`}
                      disabled={currentPage === totalPages + 1}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            )}
      </div>
    </div>
  );
};

export default Sourcing;
