function removeSpacesInNegativeParentheses(str) {
  let result = "";
  let stack = [];
  let i = 0;

  while (i < str.length) {
    if (str[i] === "-" && str[i + 1] === "(") {
      // Start of a `-()` group
      result += "-(";
      stack.push("(");
      i += 2;

      let innerContent = "";
      while (stack.length > 0 && i < str.length) {
        if (str[i] === "(") {
          stack.push("(");
          innerContent += "(";
        } else if (str[i] === ")") {
          stack.pop();
          if (stack.length > 0) {
            innerContent += ")";
          }
        } else {
          innerContent += str[i];
        }
        i++;
      }

      // Remove spaces within the captured content
      innerContent = innerContent.replace(/\s+/g, "");
      result += innerContent + ")";
    } else {
      result += str[i];
      i++;
    }
  }

  return result;
}

function cleanString(str) {
  // Step 1: Split the string by spaces
  let splitArray = str.split(" ").filter(Boolean);

  // Step 2: Filter out elements that start with "-" or are in the form "-(...)".
  let filteredArray = splitArray.filter((item) => {
    // Remove if it starts with "-" or is in the form of "-(...)"
    return !(item.startsWith("-") || item.startsWith("-("));
  });

  // Step 3: Join the remaining valid parts back into a string
  return filteredArray.join(" ");
}

export function removeNegativeValues(str) {
  let removeSpaceStr = removeSpacesInNegativeParentheses(str);
  let filteredSkillValue = cleanString(removeSpaceStr);

  return filteredSkillValue;
}

export function javaDeveloperSkillClusterLabelUpdation(value) {
  const skillMapping = new Map([
    [
      "Java (scale OR(Scalability) OR(Seconds) OR(Million) OR(Throughput) OR(Scalable) OR(Transactions) OR(Latency)) -Cloud -Kubernetes -Microservices -AWS",
      "Java, Scalable apps",
    ],
    [
      "Java (SQL OR(Mysql)) Spring -(scale OR(Scalability) OR(Seconds) OR(Million) OR(Throughput) OR(Scalable) OR(Transactions) OR(Latency)) -Cloud -Kubernetes -Microservices -AWS",
      "Java, SQL, Spring",
    ],
    [
      "Java Spring (Rest OR(Restful) OR(API) OR(webservice)) -MYSQL -(scale OR(Scalability) OR(Seconds) OR(Million) OR(Throughput) OR(Scalable) OR(Transactions) OR(Latency)) -Cloud -Kubernetes -Microservices -AWS",
      "Java, Spring, Webservice",
    ],
    [
      "Java Spring datastructures -(Rest OR(Restful) OR(API) OR(webservice)) -MYSQL -(scale OR(Scalability) OR(Seconds) OR(Million) OR(Throughput) OR(Scalable) OR(Transactions) OR(Latency)) -Cloud -Kubernetes -Microservices -AWS",
      "Java, Spring, Datastructures",
    ],
    [
      "Java (Javascript OR(Code)) -Spring -datastructures -(Rest OR(Restful) OR(API) OR(webservice)) -MYSQL -(scale OR(Scalability) OR(Seconds) OR(Million) OR(Throughput) OR(Scalable) OR(Transactions) OR(Latency)) -Cloud -Kubernetes -Microservices -AWS",
      "Java, JS",
    ],
    [
      "Java Spring boot Aws microservices (mongodb OR(MONGO) OR(NoSQL)) -Kubernetes ",
      "Java, SpringBoot, AWS, Microservices, NoSQL",
    ],
    [
      "Java Spring boot Aws microservices maven -(mongodb OR(MONGO) OR(NoSQL)) -Kubernetes ",
      "Java, SpringBoot, AWS, Microservices, CI/CD",
    ],
    [
      "Java Spring boot Aws microservices -maven -(mongodb OR(MONGO) OR(NoSQL)) -Kubernetes ",
      "Java, SpringBoot, AWS, Microservices",
    ],
    [
      "Java Spring boot (sql OR(mysql)) (hibernate OR JPA OR jdbc OR orm) -(rest OR restful OR restapi) -microservices -aws ",
      "Java Spring boot (sql OR(mysql)) (hibernate OR JPA OR jdbc OR orm)",
    ],
    [
      "Java -spring -MYSQL -(API OR(rEST) OR(Restful) OR(Webservices))  -AWS -boot -Microservices -Kubernetes",
      "Java",
    ],
    [
      "Java Spring boot database -(sql OR(mysql)) -microservices -aws ",
      "Java Spring boot database",
    ],
    [
      "Java Spring boot Aws microservices -(mongodb OR(MONGO) OR(NoSQL)) -Kubernetes ",
      "Java Spring boot Aws Microservices",
    ],
    [
      "Java spring Kubernetes (mongo OR Mongodb)",
      "Java, Spring, Kubernetes, MongoDB",
    ],
    ["Java spring Kubernetes -(mongo OR Mongodb)", "Java, Spring, Kubernetes"],
    [
      "Java spring (API OR(rEST) OR(Restful) OR(Webservices))  -AWS -boot -Microservices -Kubernetes",
      "Java, Spring, API",
    ],
    [
      "Java spring MYSQL -(API OR(rEST) OR(Restful) OR(Webservices))  -AWS -boot -Microservices -Kubernetes",
      "Java, Spring, MysQL",
    ],
    [
      "Java spring -MYSQL -(API OR(rEST) OR(Restful) OR(Webservices))  -AWS -boot -Microservices -Kubernetes",
      "Java spring",
    ],
    [
      "Java Spring boot Aws (mongodb OR(MONGO) OR(NoSQL)) -microservices -Kubernetes ",
      "Java, SpringBoot, AWS, NoSQL",
    ],
    [
      "Java Spring boot Aws (ci OR(cd) OR(Maven)) -(mongodb OR(MONGO) OR(NoSQL)) -microservices -Kubernetes ",
      "Java, SpringBoot, AWS, CI/CD",
    ],
    [
      "Java Spring boot Aws -(ci OR(cd) OR(Maven)) -(mongodb OR(MONGO) OR(NoSQL)) -microservices -Kubernetes ",
      "Java, SpringBoot, AWS",
    ],
    [
      "Java Spring boot (mongodb OR(MONGO) OR(NoSQL)) (rest OR(restful)) -Aws -Kubernetes ",
      "Java, SpringBoot, Rest, NoSQL",
    ],
    [
      "Java Spring boot (mongodb OR(MONGO) OR(NoSQL)) -(rest OR(restful)) -Aws -Kubernetes ",
      "Java, SpringBoot, NoSQL",
    ],
    [
      "Java Spring boot maven (rest OR(restful)) -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes ",
      "Java, SpringBoot, Rest, CI/CD",
    ],
    [
      "Java Spring boot maven -(rest OR(restful)) -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes ",
      "Java Spring boot maven",
    ],
    [
      "Java Spring boot (rest OR(restful)) webservices -maven -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes ",
      "Java, SpringBoot, Rest",
    ],
    [
      "Java Spring boot (rest OR(restful)) -webservices -maven -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes ",
      "Java, SpringBoot, Rest",
    ],
    [
      "Java Spring boot (rest OR(restful)) -maven -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes ",
      "Java SpringBoot, Rest",
    ],
    [
      "Java Spring boot (sql OR(mysql)) html -(rest OR(restful)) -maven -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes ",
      "Java, SpringBoot, SQL",
    ],
    [
      "Java Spring boot (sql OR(mysql)) -html -(rest OR(restful)) -maven -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes ",
      "Java, SpringBoot, SQL",
    ],
    [
      "Java Spring boot -(sql OR(mysql)) -(rest OR(restful)) -maven -(mongodb OR(MONGO) OR(NoSQL)) -Aws -Kubernetes ",
      "Java Spring boot",
    ],
    ["java (elastic search OR elk) cloud", "java, elastic search/elk, cloud"],
    [
      "java (elastic search OR elk) spring -cloud",
      "java, elastic search/elk, spring",
    ],
    [
      "java (elastic search OR elk) (sql OR mysql) -spring -cloud",
      "java, elastic search/elk, sql/mysql",
    ],
    [
      "java (elastic search OR elk) -(sql OR mysql) -spring -cloud",
      "java, elastic search/elk",
    ],

    // hydrabad location
    ["java spring microservices", "Java, Spring, Microservices"],
    ["java spring cloud -microservices", "Java, Spring, Cloud"],
    ["java spring aws -cloud -microservices", "Java, Spring, Aws"],
    [
      "java spring (rest OR restapi OR restful) -aws -cloud -microservices",
      "Java, spring, rest/restful ",
    ],
    [
      "java spring (sql OR mysql) -(rest OR restapi OR restful) -aws -cloud -microservices",
      "java, spring, sql/mysql",
    ],
    [
      "java spring -(sql OR mysql) -(rest OR restapi OR restful) -aws -cloud -microservices",
      "java, spring",
    ],
    ["java (Nosql OR mongodb OR mongo) -spring ", "java, Nosql, mongodb"],

    ["Java Spring boot aws kubernetes", "Java, Spring boot, aws, kubernetes"],
    [
      "Java Spring boot aws microservices -kubernetes",
      "Java, Spring boot, aws, microservices",
    ],
    [
      "Java Spring boot aws -microservices -kubernetes",
      "Java, Spring boot, aws",
    ],
    [
      "Java Spring boot microservices cloud -aws ",
      "Java, Spring boot, microservices, cloud",
    ],
    [
      "Java Spring boot microservices (mongo OR mongodb OR nosql) -cloud -aws ",
      "Java, Spring boot, microservices, mongodb, nosql",
    ],
    [
      "Java Spring boot microservices (rest OR restful OR restapi) -(mongo OR mongodb OR nosql) -cloud -aws ",
      "Java, Spring boot, microservices, rest/restful",
    ],
    [
      "Java Spring boot microservices -(rest OR restful OR restapi) -(mongo OR mongodb OR nosql) -cloud -aws ",
      "Java, Spring boot, microservices",
    ],
    [
      "Java Spring boot (sql OR mysql) (rest OR restful OR restapi) -microservices -aws ",
      "Java, Spring boot, sql/mysql, rest/restful",
    ],
    [
      "Java Spring boot (sql OR(mysql)) (hibernate OR JPA OR jdbc OR orm) -(rest OR restful OR restapi) -microservices -aws ",
      "Java, Spring boot, sql/mysql, hibernate/ORM",
    ],
    [
      "Java Spring boot (sql OR(mysql)) (ds OR(data structures) OR(algorithms)) -(hibernate OR JPA OR jdbc OR orm) -(rest OR restful OR restapi) -microservices -aws ",
      "Java Spring boot, sql/mysql, data structures, algorithms",
    ],
    [
      "Java Spring boot (sql OR(mysql)) -(ds OR(data structures) OR(algorithms)) -(hibernate OR JPA OR jdbc OR orm) -(rest OR restful OR restapi) -microservices -aws ",
      "Java, Spring boot, sql/mysql",
    ],
    [
      "Java Spring boot database -(sql OR(mysql)) -microservices -aws ",
      "Java, Spring boot database",
    ],
    [
      "Java Spring boot -database -(sql OR(mysql)) -microservices -aws ",
      "Java, Spring boot",
    ],
    [
      "Java Spring boot (ds OR(data structures) OR(algorithms)) -database -(sql OR(mysql)) -microservices -aws ",
      "Java, Spring boot, data structures, algorithms",
    ],
    [
      "Java Spring boot -(ds OR(data structures) OR(algorithms)) -database -(sql OR(mysql)) -microservices -aws ",
      "Java, Spring boot",
    ],

    [
      "java (sql OR mysql) -microservices -aws",
      "java, sql/mysql, microservices",
    ],
    ["java spring -(sql OR mysql) -microservices -aws", "java, spring"],
    ["java -spring -(sql OR mysql) -microservices -aws", "java"],

    ["Java Spring boot aws kubernetes", "Java, Spring boot, Aws, Kubernetes"],
    [
      "Java Spring boot aws microservices -kubernetes",
      "Java, Spring boot, Aws, Microservices",
    ],
    [
      "Java Spring boot microservices (mongo OR mongodb OR nosql) -cloud -aws ",
      "Java, Spring boot, microservices, mongodb, nosql",
    ],
    [
      "Java Spring boot microservices (rest OR restful OR restapi) -(mongo OR mongodb OR nosql) -cloud -aws ",
      "Java, Spring boot, microservices, rest/restful",
    ],
    [
      "Java Spring boot microservices -(rest OR restful OR restapi) -(mongo OR mongodb OR nosql) -cloud -aws ",
      "Java, Spring boot, microservices",
    ],
    [
      "Java Spring boot (sql OR mysql) (rest OR restful OR restapi) -microservices -aws ",
      "Java, Spring boot, sql/mysql, rest/restful",
    ],
    [
      "Java Spring boot (sql OR(mysql)) (hibernate OR JPA OR jdbc OR orm) -(rest OR restful OR restapi) -microservices -aws ",
      "Java, Spring boot, sql/mysql, hibernate/ORM",
    ],
    [
      "Java Spring boot (sql OR(mysql)) (ds OR(data structures) OR(algorithms)) -(hibernate OR JPA OR jdbc OR orm) -(rest OR restful OR restapi) -microservices -aws ",
      "Java Spring boot, sql/mysql, data structures, algorithms",
    ],
    [
      "Java Spring boot (sql OR(mysql)) -(ds OR(data structures) OR(algorithms)) -(hibernate OR JPA OR jdbc OR orm) -(rest OR restful OR restapi) -microservices -aws ",
      "Java, Spring boot, sql/mysql",
    ],
    [
      "Java Spring boot database -(sql OR(mysql)) -microservices -aws ",
      "Java, Spring boot database",
    ],
    [
      "Java Spring boot -database -(sql OR(mysql)) -microservices -aws ",
      "Java, Spring boot",
    ],
    [
      "Java Spring boot (ds OR(data structures) OR(algorithms)) -database -(sql OR(mysql)) -microservices -aws ",
      "Java, Spring boot, data structures, algorithms",
    ],
    [
      "Java Spring boot -(ds OR(data structures) OR(algorithms)) -database -(sql OR(mysql)) -microservices -aws ",
      "Java, Spring boot",
    ],

    [
      "java spring boot (nosql OR(mongo) OR(mongodb)) -aws -cloud -kubernetes",
      "java, spring boot, nosql, mongodb",
    ],
    [
      "java spring boot microservices -(nosql OR(mongo) OR(mongodb)) -aws -cloud -kubernetes",
      "java, spring boot, microservices",
    ],
    [
      "java spring boot (sql OR mysql) -microservices -(nosql OR(mongo) OR(mongodb)) -aws -cloud -kubernetes",
      "java, spring boot, sql/mysql",
    ],
    [
      "java spring boot -(sql OR mysql) -microservices -(nosql OR(mongo) OR(mongodb)) -aws -cloud -kubernetes",
      "java, spring boot ",
    ],
    [
      "java spring (rest OR(restful)) aws -boot",
      "java, spring, rest/restful, aws",
    ],
    [
      "java spring (rest OR(restful)) (nosql OR(mongo) OR(mongodb)) -aws -boot",
      "java, spring, rest/restful, nosql, mongodb",
    ],
    [
      "java spring (rest OR(restful)) (sql OR(mysql)) -(nosql OR(mongo) OR(mongodb)) -aws -boot",
      "java, spring, rest/restful, sql/mysql ",
    ],
    [
      "java spring (rest OR(restful)) -(sql OR(mysql)) -(nosql OR(mongo) OR(mongodb)) -aws -boot",
      "java, spring, rest/restful",
    ],
    [
      "java spring (sql OR(mysql)) aws -(rest OR(restful) OR(restAPI)) -boot",
      "java, spring, sql/mysql, aws, rest/restful",
    ],
    [
      "java spring (sql OR(mysql)) cloud -aws -(rest OR(restful) OR(restAPI)) -boot",
      "java, spring, sql/mysql, cloud, aws",
    ],
    [
      "java spring (sql OR(mysql)) azure -cloud -aws -(rest OR(restful) OR(restAPI)) -boot",
      "java, spring, sql/mysql, azure",
    ],
    [
      "java spring (sql OR(mysql)) (web services OR(webservices) OR(soap)) -azure -cloud -aws -(rest OR(restful) OR(restAPI)) -boot",
      "java, spring, sql/mysql, web services ",
    ],
    [
      "java spring (sql OR(mysql)) (database OR db) -(web services OR(webservices) OR(soap)) -azure -cloud -aws -(rest OR(restful) OR(restAPI)) -boot",
      "java, spring, sql/mysql, database",
    ],
    [
      "java spring (sql OR(mysql)) html -(database OR(db)) -(web services OR(webservices) OR(soap)) -azure -cloud -aws -(rest OR(restful) OR(restAPI)) -boot",
      "java, spring, sql/mysql, html",
    ],
    [
      "java spring (sql OR(mysql)) -html -(database OR db) -(web services OR(webservices) OR(soap)) -azure -cloud -aws -(rest OR(restful) OR(restAPI)) -boot",
      "java, spring, sql/mysql",
    ],
    [
      "java spring (sql OR(mysql)) software -html -(database OR db) -(web services OR(webservices) OR(soap)) -azure -cloud -aws -(rest OR(restful) OR(restAPI)) -boot",
      "java, spring, sql/mysql, softwares",
    ],
    [
      "java spring (cloud OR(aws) OR(azure)) -(sql OR(mysql)) -(rest OR(restful) OR(restAPI)) -boot",
      "java, spring, cloud/aws/azure",
    ],
    [
      "java spring (architecture OR(design) OR(designing) OR(high level) OR(hld) OR(low level))) -(cloud OR(aws) OR(azure)) -(sql OR(mysql)) -(rest OR(restful) OR(restAPI)) -boot",
      "java, spring, architecture, HLD/LLD",
    ],
    [
      "java spring engineer -(architecture OR(design) OR(designing) OR(high level) OR(hld) OR(low level))) -(cloud OR(aws) OR(azure)) -(sql OR(mysql)) -(rest OR(restful) OR(restAPI)) -boot",
      "java, spring",
    ],
    [
      "java spring -(test OR(testing)) -(architecture OR(design) OR(designing) OR(high level) OR(hld) OR(low level))) -(cloud OR(aws) OR(azure)) -(sql OR(mysql)) -(rest OR(restful) OR(restAPI)) -boot",
      "java, spring",
    ],

    // pune location
    ["Java (aws OR cloud) -microservices", "Java, cloud"],
    ["Java -(aws OR cloud) -microservices", "Java"],
  ]);

  // Fetch the label from the map, or apply the fallback logic
  const label =
    skillMapping.get(value) || removeNegativeValues(value) || "Others";

  return {
    label,
    value,
  };
}

export function engineeringManagerSkillClusterLabelUpdation(value) {
  const skillMapping = new Map([
    ["Java microservices", "Java, Microservices"],
    ["Java (aws OR cloud) -microservices", "Java, Aws"],
    [
      "Java (architecture OR hld OR high level) -(aws OR cloud) -microservices",
      "Java, Architecture",
    ],
    [
      "Java -(architecture OR hld OR high level) -(aws OR cloud) -microservices",
      "Java",
    ],
    [
      '("Full stack" OR(FullStack)) (Angular OR(AngularJS)) (Java OR(Node) OR(Nodejs)) AWS',
      "FullStack, Angular, Java/Node, AWS",
    ],
  ]);

  // Fetch the label from the map, or apply the fallback logic
  const label =
    skillMapping.get(value) || removeNegativeValues(value) || "Others";

  return {
    label,
    value,
  };
}

export function dataScientistSkillClusterLabelUpdation(value) {
  const skillMapping = new Map([
    [
      "deep (tensorflow OR tensor) (ML OR machine learning)",
      "Deep Learning, Tensorflow, ML",
    ],
    [
      "deep (ML OR machine learning) -(tensorflow OR tensor) ",
      "Deep Learning, ML",
    ],
    [
      "(ML OR(machine learning)) (genAI OR(gen AI) OR(generative) OR(llm) OR(language model)) -Deep ",
      "ML, GenAI/LLM",
    ],
    [
      "(NLP OR(NLTK) OR(Natural)) (ML OR(Machine Learning)) -(genAI OR(gen AI) OR(llm) OR(Language Model)) -deep",
      "NLP/NLTK, ML",
    ],
    [
      "(Model OR(Pandas) OR(Scikit)) (ML OR(Machine Learning)) -(NLP OR(NLTK) OR(Natural)) -(genAI OR(gen AI) OR(llm) OR(Language Model)) -deep",
      "Data Models, ML",
    ],
    [
      "(ML OR(Machine Learning)) -(NLP OR(NLTK) OR(Natural)) -(genAI OR(gen AI) OR(llm) OR(Language Model)) -deep -(Model OR(Pandas) OR(Scikit)) ",
      "ML",
    ],
    [
      "(statistics OR statistical OR predictive) (analytics OR analytical OR modelling) -(ML OR(Machine Learning))",
      "Statistics/Predictive, Analytics",
    ],
    [
      "(analytics OR analytical OR modelling) -(ML OR(Machine Learning)) -(statistics OR statistical OR predictive)",
      "Analytics",
    ],
    ["deep (tensorflow OR tensor) ", "Deep Learning, Tensorflow"],
    ["deep  -(tensorflow OR tensor)", "Deep Learning"],
    ["(tensorflow OR tensor) -Deep", "Tensorflow"],
    ["(NLP OR(NLTK) OR(Natural)) -(tensorflow OR tensor) -Deep", "NLP/NLTK"],
    [
      "(genAI OR(gen AI) OR(generative) OR(llm) OR(language model)) -(NLP OR(NLTK) OR(Natural)) -(tensorflow OR(tensor)) -Deep",
      "GenAI/LLM",
    ],
    ["(ML OR machine learning) deep", "machine learning, deep learning"],
    [
      "(ML OR machine learning) (genAI OR gen AI OR generative OR llm OR language model) -deep",
      "machine learning, generative AI ",
    ],
    [
      "(ML OR machine learning) (statistics OR(statistical) OR(predictive)) -(genAI OR gen AI OR generative OR llm OR language model) -deep",
      "machine learning, statistics/predictive",
    ],
    [
      "(ML OR machine learning) -(statistics OR(statistical) OR(predictive)) -(genAI OR gen AI OR generative OR llm OR language model) -deep",
      "machine learning ",
    ],
    [
      "(analytics OR analytical OR modelling) -(ML OR machine learning) ",
      "analytics /predictive modeling",
    ],
  ]);

  // Fetch the label from the map, or apply the fallback logic
  const label =
    skillMapping.get(value) || removeNegativeValues(value) || "Others";

  return {
    label,
    value,
  };
}

export function mernStackSkillClusterLabelUpdation(value) {
  let label;
  if (
    value ===
    "(fullstack OR(Full stack) OR(MERN)) (Node OR(Nodejs)) (express OR(Expressjs)) (react OR(ReactJS)) -spring -asp"
  ) {
    label = "Nodejs, expressjs, reactjs";
  } else if (
    value ===
    "(fullstack OR(Full stack) OR(MERN)) (Node OR(Nodejs)) (express OR(Expressjs)) (react OR(ReactJS)) redux -spring -asp"
  ) {
    label = "Nodejs, expressjs, reactjs, redux";
  } else if (
    value ===
    "(fullstack OR(Full stack) OR(MERN)) (Node OR(Nodejs)) (express OR(Expressjs)) (react OR(ReactJS)) -redux -spring -asp"
  ) {
    label = "Nodejs, expressjs, reactjs";
  } else if (
    value === "(node OR nodejs) (react OR reactjs) (LLM OR language model)"
  ) {
    label = "Nodejs, Reactjs, LLM";
  } else if (
    value ===
    "(fullstack OR(Full stack) OR(MERN)) (Node OR(Nodejs)) (express OR(Expressjs)) (react OR(ReactJS)) -spring -asp"
  ) {
    label = "Full stack, MERN, Nodejs, Expressjs, react";
  } else if (
    value ===
    ' (fullstack OR(Full stack) OR(MERN)) (Node OR(Nodejs)) (express OR(Expressjs)) (react OR(ReactJS)) redux -spring -asp"'
  ) {
    label = "fullstack , Node, express, reactjs, redux";
  } else if (
    value ===
    "(fullstack OR(Full stack) OR(MERN)) (Node OR(Nodejs)) (express OR(Expressjs)) (react OR(ReactJS)) -redux -spring -asp"
  ) {
    label = "fullstack. Node, react";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function productManagerSkillClusterLaelUpdation(value) {
  let label;
  if (
    value ==
      '("software engineer" OR("Software developer") OR("System Engineer") OR("Software development engineer"))' ||
    value ==
      '("software engineer" OR "Software developer" OR "System Engineer" OR "Software development engineer")' ||
    value ==
      '"software engineer" OR "Software developer" OR "System Engineer" OR "Software development engineer"'
  ) {
    label = "Past : Software Engineer";
  } else if (
    value ==
    '(Rest OR(Restful) OR(Restapi) OR(API) OR(Graphql)) ("software engineer" OR("Software developer") OR("System Engineer") OR("Software development engineer"))'
  ) {
    label = "API, Past : Software Engineer";
  } else if (
    value ==
    `(Java OR Nodejs OR Python) (\"software engineer\" OR(\"Software developer\") OR(\"System Engineer\") OR(\"Software development engineer\")) -(Rest OR(Restful) OR(Restapi) OR(API) OR(Graphql))`
  ) {
    label = "Any Language, Past : Software Engineer";
  } else if (
    value ==
    `(SQL OR(Mysql)) (\"software engineer\" OR(\"Software developer\") OR(\"System Engineer\") OR(\"Software development engineer\")) -(Rest OR(Restful) OR(Restapi) OR(API) OR(Graphql)) -(Java OR(Nodejs) OR(Python))`
  ) {
    label = "Database, Past : Software Engineer";
  } else if (
    value ==
    `(\"software engineer\" OR(\"Software developer\") OR(\"System Engineer\") OR(\"Software development engineer\")) -(Rest OR(Restful) OR(Restapi) OR(API) OR(Graphql)) -(Java OR(Nodejs) OR(Python)) -(SQL OR(Mysql))`
  ) {
    label = "Past : Software Engineer";
  } else if (value === "(User OR(Research)) (Data OR(Analytics))") {
    label = "User Research, Data/Analytics";
  } else if (value === "(User OR(Research)) -(Data OR(Analytics))") {
    label = "User Research";
  } else if (value === "(Data OR(Analytics)) -(User OR(Research))") {
    label = "Data/Analytics";
  } else if (
    value === "(Speech OR(voice) OR(Conversational) OR(NLP) OR(ML) OR(AI))"
  ) {
    label = "Speech/Voice/NLP/AI/ML";
  } else if (
    value === "Growth (GTM OR goto market OR go to market) (SaaS OR B2B)"
  ) {
    label = "Product growth/ GTM, SaaS/B2B";
  } else if (
    value === "Growth (GTM OR goto market OR go to market) -(SaaS OR B2B)"
  ) {
    label = "Product growth/ GTM";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function dataEngineerSkillClusterUpdation(value) {
  let label;
  if (value === "Python (etl OR(informatica)) azure") {
    label = "Python, ETL, Azure";
  } else if (value === "Python (etl OR(informatica)) AWS -azure") {
    label = "Python, ETL, AWS";
  } else if (value === "Python (etl OR(informatica)) -AWS -azure") {
    label = "Python, ETL";
  } else if (value === "Python -(etl OR(informatica)) -AWS -azure") {
    label = "Python";
  } else if (value === "Python pyspark -(etl OR(informatica)) ") {
    label = "Python, Pyspark";
  } else if (value === "Python -pyspark -(etl OR(informatica))") {
    label = "Python";
  } else if (value === "(dwh OR ETL OR warehouse) -Python ") {
    label = "Datawarehouse/DWH , ETL";
  } else if (
    value === "(AZURE OR(AWS) OR(Cloud)) -(dwh OR(ETL) OR(warehouse)) -Python "
  ) {
    label = "Aws/Azure/Cloud";
  } else if (
    value ===
    "(sql OR(mysql) OR(database)) -(AZURE OR(AWS) OR(Cloud)) -(dwh OR(ETL) OR(warehouse)) -Python"
  ) {
    label = "Sql/mysql, database";
  } else if (value === '"Data Engineer"') {
    label = "others";
  } else if (value === "Python (etl OR(informatica)) ") {
    label = "Python, informatica/ETL";
  } else if (
    value === "(AZURE OR(AWS) OR(Cloud)) -(dwh OR(ETL) OR(warehouse)) -Python"
  ) {
    label = "AWS";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "others",
    value: value,
  };
}

export function testEngineerSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    'Java Selenium (Rest assured OR Restassured) (testng OR "Test ng")'
  ) {
    label = "Java, Selenium, Rest Assured, TestNG";
  } else if (
    value ===
    'Java Selenium (Rest assured OR Restassured) (testng OR "Test ng") Postman'
  ) {
    label = "Java, Selenium, Rest Assured, TestNG";
  } else if (
    value ===
    'Java Selenium (Rest assured OR Restassured) (testng OR "Test ng") -Postman'
  ) {
    label = "Java, Selenium, Rest Assured, TestNG, Testing Tools";
  } else if (value === "Automation (javascript OR(typescript)) API MySQL") {
    label = "Automation, Javascript/Typescript, API, SQL";
  } else if (
    value === "Automation (javascript OR(typescript)) API Rest -MySQL"
  ) {
    label = "Automation, Javascript/Typescript, Rest API, SQL";
  } else if (
    value === "Automation (javascript OR(typescript)) API -Rest -MySQL"
  ) {
    label = "Automation, Javascript/Typescript, API, SQL";
  } else if (value === "Automation (javascript OR(typescript)) MySQL -API ") {
    label = "Automation, Javascript/Typescript, MySQL";
  } else if (value === "Automation (javascript OR(typescript)) -MySQL -API ") {
    label = "Automation, Javascript/Typescript";
  } else if (value === "Automation java appium") {
    label = "Automation, java, appium";
  } else if (value === `Automation java "rest assured" -appium`) {
    label = "Automation, java, rest assured";
  } else if (value === "Automation java selenium -rest -assured -appium") {
    label = "Automation, java, selenium ";
  } else if (value === "Automation java -selenium -rest -assured -appium") {
    label = "Automation, java ";
  } else if (value === "javascript cypress") {
    label = "Javascript, Cypress ";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function iosDeveloperSkillClusterUpdation(value) {
  let label;
  if (value === "Swift Senior") {
    label = "iOS, Swift";
  } else if (
    value === "(Objective OR ObjectiveC) swift (angular OR Angularjs)"
  ) {
    label = "Objective-C, Swift, Angularjs";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function salesForceDeveloperSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    "(Salesforce OR(SFDC)) Apex (Triggers OR Trigger) (LWC OR Lightning) "
  ) {
    label = "SFDC, Apex, Triggers, LWC";
  } else if (
    value ===
    "(Salesforce OR(SFDC)) Apex (LWC OR Lightning) -(Triggers OR Trigger) "
  ) {
    label = "SFDC, Apex, Lightning, Salesforce Stack";
  } else if (
    value ===
    "(Salesforce OR(SFDC)) (LWC OR Lightning) (java OR .net) (Triggers OR Trigger)"
  ) {
    label = "SFDC, java/.net, Lightning, trigger";
  } else if (
    value ===
    "(Salesforce OR(SFDC)) (LWC OR Lightning) (java OR .net) -apex -(Triggers OR Trigger)"
  ) {
    label = "SFDC, java/.net, Lightning";
  } else if (
    value ===
    "(Salesforce OR(SFDC)) (LWC OR Lightning) (java OR .net) apex -(Triggers OR Trigger)"
  ) {
    label = "SFDC, java/.net, Lightning, apex";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function pythonFullstackSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    "(fullstack OR(Full stack)) Python DJANGO (Flask OR(FAST) OR(FastAPI)) (React OR(ReactJS)) -c# -Spring"
  ) {
    label = "Python, DJANGO, ReactJS";
  } else if (
    value ===
    "Python DJANGO (Flask OR(FAST) OR(FastAPI)) (React OR(ReactJS)) -c# -Spring -(fullstack OR(Full stack))"
  ) {
    label = "Python, DJANGO, ReactJS, Python Libraries";
  } else if (value === "python (react OR reactjs) (LLM OR language model)") {
    label = "Python, Reactjs, LLM";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function javaFullstackSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    "Java Spring JDBC (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) "
  ) {
    label = "Java, Spring, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring Hibernate MVC (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC"
  ) {
    label = "Java, Spring MVC, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring Hibernate (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -MVC"
  ) {
    label = "Java, Spring, Hibernate, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring boot mysql (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -Hibernate"
  ) {
    label = "Java, Spring, SQL, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring boot (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -Hibernate -mysql"
  ) {
    label = "Java, Spring boot, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring AWS (SQL OR(MySQL)) (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -Hibernate -boot"
  ) {
    label = "Java, Spring, AWS, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -Hibernate -boot -AWS -c# -cloud"
  ) {
    label = "Java, Spring, ReactJS/AngularJS, JS";
  } else if (
    value ===
    "Java Spring AWS (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -Hibernate -boot -(SQL OR(MySQL))"
  ) {
    label = "Java, Spring, DB, ReactJS/AngularJS";
  } else if (
    value ===
    "Java Spring cloud (React OR(ReactJS) OR(Angular) OR(AngularJS)) (fullstack OR(Full stack)) -JDBC -Hibernate -boot -AWS -c#"
  ) {
    label = "Java, Spring, Cloud, ReactJS/AngularJS";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function flutterSkillClusterUpdation(value) {
  let label;
  if (value === "flutter Dart (Riverpod OR river pod OR Bloc)") {
    label = "flutter, Dart, Riverpod/Bloc";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function networkEngineerSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    "(SDLAN OR WLAN OR SDWAN OR WAN) (routing OR router OR switching) cisco"
  ) {
    label = "SDLAN /WLAN /SDWAN /WAN, routing, switching";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function linuxAdministratorSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    "Linux ((administrator) OR(administration) OR(admin)) ((AIX AND Unix) OR (AIX AND redhat) OR (Unix AND redhat)) RHEL"
  ) {
    label = "Linux, AIX, Unix, Redhat, RHEL";
  } else if (
    value ===
    "Linux ((administrator) OR(administration) OR(admin)) ((AIX AND Unix) OR (AIX AND redhat) OR (Unix AND redhat)) (incident OR(change))  -RHEL"
  ) {
    label = "Linux, AIX, Unix, Redhat, incident/change mgmt";
  } else if (
    value ===
    "Linux ((administrator) OR(administration) OR(admin)) ((AIX AND Unix) OR (AIX AND redhat) OR (Unix AND redhat)) (certified OR(certification)) -(incident OR(change))  -RHEL"
  ) {
    label = "Linux, AIX, Unix, Redhat, certified";
  } else if (
    value ===
    "Linux ((administrator) OR(administration) OR(admin)) ((AIX AND Unix) OR (AIX AND redhat) OR (Unix AND redhat)) -(certified OR(certification)) -(incident OR(change))  -RHEL"
  ) {
    label = "Linux, AIX, Unix, Redhat";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function pythonDeveloperSkillClusterUpdation(value) {
  let label;
  if (
    value ===
    "python (django OR(flask)) (rest OR(restful) OR(restapi)) (LLM OR language model  OR GenAI OR GAI OR generative AI OR GEN AI)"
  ) {
    label = "Python, Django/flask, rest API, GAI/Generative AI/LLM";
  } else if (value === "python django aws s3 rds -spring -angular -react") {
    label = "python, Django, AWS, S3, RDS";
  } else if (value === "python django aws s3 -rds -spring -angular -react") {
    label = "python, Django, AWS, S3";
  } else if (
    value ===
    "python django aws (rest OR(restful) OR(api)) (nosql OR(mongo) OR(mongodb)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS, rest/API, mongoDB";
  } else if (
    value ===
    "python django aws (rest OR(restful) OR(api)) (database OR db) -(nosql OR(mongo) OR(mongodb)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS, rest/API, Databases";
  } else if (
    value ===
    "python django aws (rest OR(restful) OR(api)) -(database OR db) -(nosql OR(mongo) OR(mongodb)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS, rest/API";
  } else if (
    value ===
    "python django aws (sql OR(mysql)) (architecture OR(design) OR(designing)) -(database OR(db)) -(rest OR(restful) OR(api)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS, sql/mysql, architecture/design";
  } else if (
    value ===
    "python django aws (sql OR(mysql)) -(architecture OR(design) OR(designing)) -(database OR(db)) -(rest OR(restful) OR(api)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS, sql";
  } else if (
    value ===
    "python django aws systems -(sql OR(mysql)) -(rest OR(restful) OR(api)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS, systems";
  } else if (
    value ===
    "python django aws -systems -(sql OR(mysql)) -(rest OR(restful) OR(api)) -s3 -spring -angular -react"
  ) {
    label = "python, Django, AWS";
  }

  // else if(value === ){
  //   label = ""
  // }
  else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function uiDeveloperSkillClusterUpdation(value) {
  let label;
  if (value === "(react OR redux) html5") {
    label = "React, Redux, html5";
  } else if (value === "(react OR redux) -html5") {
    label = "React, Redux";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function rubyOnRailsSkillClusterUpdation(value) {
  let label;
  if (value === `("ruby on rails" OR "RoR") SQL REST`) {
    label = "RoR, SQL, REST ";
  } else if (value === `(\"ruby on rails\" OR \"RoR\") SQL -REST`) {
    label = "RoR, SQL";
  } else if (value === `(\"ruby on rails\" OR \"RoR\")  -SQL -REST`) {
    label = "RoR";
  } else {
    label = removeNegativeValues(value);
  }

  return {
    label: label || "Others",
    value: value,
  };
}

export function productDesignerSkillClusterLabelUpdation(value) {
  const skillMapping = new Map([["wireframes HTML", "Wireframes, HTML"]]);

  // Fetch the label from the map, or apply the fallback logic
  const label =
    skillMapping.get(value) || removeNegativeValues(value) || "Others";

  return {
    label,
    value,
  };
}

export function meanDeveloperSkillClusterLabelUpdation(value) {
  const skillMapping = new Map([
    [
      "(Mean OR ((mongo OR mongodb) (node OR nodejs) (angular OR angularjs)))",
      "Mongodb, Angularjs, Nodejs",
    ],
    [
      "(Mean OR ((mongo OR mongodb) (node OR nodejs) (angular OR angularjs))) AWS",
      "Mongodb, Angularjs, Nodejs, AWS",
    ],
    [
      "(Mean OR ((mongo OR mongodb) (node OR nodejs) (angular OR angularjs))) -AWS",
      "Mongodb, Angularjs, Nodejs",
    ],
    [
      "(Mean OR ((mongo OR mongodb) (node OR nodejs) (angular OR angularjs))) ",
      "Mongodb, Angularjs, Nodejs",
    ],
    [
      "(Mean OR ((mongo OR mongodb) (node OR nodejs) (angular OR angularjs))) (AWS OR(azure))",
      "Monogodb, Angularjs, Nodejs, AWS/Azure",
    ],
    [
      "(Mean OR ((mongo OR mongodb) (node OR nodejs) (angular OR angularjs))) -(AWS OR(azure))",
      "Monogdb, Angularjs, Nodejs,",
    ],
  ]);

  // Fetch the label from the map, or apply the fallback logic
  const label =
    skillMapping.get(value) || removeNegativeValues(value) || "Others";

  return {
    label,
    value,
  };
}

export function devopsEngineerSkillClusterLabelUpdation(value) {
  const skillMapping = new Map([
    ["security aws", "Cloud security, AWS"],
    ["aws (unix OR python)", "AWS, python/unix"],
    [
      "aws (ansible OR terraform OR salt) -(unix OR python)",
      "AWS, ansible/terraform",
    ],
    ["aws -(ansible OR terraform OR salt) -(unix OR python)", "AWS"],
    ["(infrastructure OR IaaS OR Iac) -aws ", "Infrastructure, Iaas/IaC"],
  ]);

  // Fetch the label from the map, or apply the fallback logic
  const label =
    skillMapping.get(value) || removeNegativeValues(value) || "Others";

  return {
    label,
    value,
  };
}

export function sharepointDeveloperSkillClusterLabelUpdation(value) {
  const skillMapping = new Map([["sharepoint SPFX", "Sharepoint, SPFx"]]);

  // Fetch the label from the map, or apply the fallback logic
  const label =
    skillMapping.get(value) || removeNegativeValues(value) || "Others";

  return {
    label,
    value,
  };
}

export function dotNetDeveloperSkillClusterLabelUpdation(value) {
  const skillMapping = new Map([["(.net OR c# OR asp) (angular OR angularjs)", ".net/asp.net, angular.js"]]);

  // Fetch the label from the map, or apply the fallback logic
  const label =
    skillMapping.get(value) || removeNegativeValues(value) || "Others";

  return {
    label,
    value,
  };
}
