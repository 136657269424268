import { Token } from "@mui/icons-material";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchedProfiles,
  setCategories,
  setCategoriesLoading,
  setIsViewedProfilesCount,
  setLoadingLocation,
  setLoadingSkillCluster,
  setLocation,
  setProfileCountLoader,
  setProfileLoader,
  setSkillCluster,
  setTotalPagedCount,
} from "../features/profileSlice/profileSlice";
import { message } from "antd";
import { logoutSuccess } from "../features/auth2/authSlice2";

function useJobsData() {
  const BASE_URL = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const isViewedProfileCount = useSelector(
    (state) => state.profile.isViewedProfilesCount
  );
  const candidates = useSelector((state) => state.profile.profiles);
  const TOKEN = localStorage.getItem("token")
    ? localStorage.getItem("token").toString()
    : "none";

  const filterLocations = (location) => {
    return location?.filter((location) => location !== "Bengaluru, Karnataka");
  };

  const getLocations = async () => {
    dispatch(setLoadingLocation(true));
    try {
      const apiResponse = await axios({
        method: "GET",
        baseURL: BASE_URL,
        url: `/v1/company/tabuddy-locations`,
      });
      const location = filterLocations(apiResponse.data);
      const locations = location.filter(
        (value) => value !== "Bengaluru, Karnataka, India" && value !== "Tamil Nadu, India"
      );
      
      dispatch(setLocation(locations));
      dispatch(setLoadingLocation(false));
    } catch (error) {
      dispatch(setLoadingLocation(false));
      message.error("Error in loading locations!");
    }
  };

  const getCategory = async (location) => {
    dispatch(setCategoriesLoading(true));
    try {
      const apiResponse = await axios({
        method: "GET",
        baseURL: BASE_URL,
        url: `/v1/company/categories/?location=${location}`,
      });
      dispatch(setCategories(apiResponse.data));
    } catch (error) {
      dispatch(setCategoriesLoading(false));
      if (error?.response?.status === 401) {
        message.error("Your session has expired. Please log in again!!");
        dispatch(logoutSuccess());
      } else {
        message.error("Error in loading categories");
      }
    }
  };

  const getSkillCluster = async (category, tag, location) => {
    dispatch(setLoadingSkillCluster(true));
    try {
      const apiResponse = await axios({
        method: "GET",
        baseURL: BASE_URL,
        url: `v1/company/booleans?category=${category}&tag=${tag}&location=${location}`,
      });
      dispatch(setSkillCluster(apiResponse.data));
    } catch (error) {
      dispatch(setLoadingSkillCluster(false));
      if (error?.response?.status === 401) {
        message.error("Your session has expired. Please log in again!!");
        dispatch(logoutSuccess());
      } else {
        message.error("Error in loading skillclusters");
      }
    }
  };

  const getFreeProfiles = async (category, tag, boolean, location) => {
    const ensureArrayBoolean = (value) => {
      if (Array.isArray(value)) {
        return value; // Already an array, return as is
      }
      if (typeof value === "string") {
        return [value]; // Convert string to array
      }
      return []; // Fallback: if it's neither string nor array, return an empty array
    };

    const arrayBoolean = ensureArrayBoolean(boolean);

    dispatch(setProfileLoader(true));
    try {
      // Construct URL parameters dynamically
      const params = new URLSearchParams({
        category,
        tag,
        location,
      });

      // Add `booleans` only if it is not empty
      if (arrayBoolean.length > 0 && arrayBoolean[0] !== "") {
        params.append("booleans", JSON.stringify(arrayBoolean));
      }

      const apiResponse = await axios({
        method: "GET",
        baseURL: BASE_URL,
        url: `/v1/company/profiles/public?${params.toString()}`,
      });

      dispatch(fetchedProfiles(apiResponse.data));
    } catch (error) {
      dispatch(setProfileLoader(false));
      message.error("Error in loading Profiles");
    }
  };

  const getProfiles = async (category, tag, boolean, location, pageNumber) => {
    const ensureArrayBoolean = (value) => {
      if (Array.isArray(value)) {
        return value; // Already an array, return as is
      }
      if (typeof value === "string") {
        return [value]; // Convert string to array
      }
      return []; // Fallback: if it's neither string nor array, return an empty array
    };

    const arrayBoolean = ensureArrayBoolean(boolean);

    dispatch(setProfileLoader(true));
    try {
      // Construct URL parameters dynamically
      const params = new URLSearchParams({
        token: TOKEN,
        pageNumber,
        pageSize: 20,
        isViewed: false,
        category,
        tag,
        location,
      });

      // Add `booleans` only if it is not empty
      if (arrayBoolean.length > 0 && arrayBoolean[0] !== "") {
        params.append("booleans", JSON.stringify(arrayBoolean));
      }

      const apiResponse = await axios({
        method: "GET",
        baseURL: BASE_URL,
        url: `/v1/company/profiles?${params.toString()}`,
      });

      dispatch(setTotalPagedCount(apiResponse.data.total_pages));
      dispatch(fetchedProfiles(apiResponse.data.data));
    } catch (error) {
      dispatch(setProfileLoader(false));
      if (error?.response?.status === 401) {
        message.error("Your session has expired. Please log in again!!");
        dispatch(logoutSuccess());
      } else {
        message.error("Error in loading Profiles!");
      }
    }
  };

  const getViewedProfiles = async (category, tag, boolean, pageNumber) => {
    dispatch(setProfileLoader(true));

    const ensureArrayBoolean = (value) => {
      if (Array.isArray(value)) {
        return value; // Already an array, return as is
      }
      if (typeof value === "string") {
        return [value]; // Convert string to array
      }
      return []; // Fallback: if it's neither string nor array, return an empty array
    };

    const arrayBoolean = ensureArrayBoolean(boolean);

    try {
      const params = new URLSearchParams({
        token: TOKEN,
        isViewed: "true",
      });

      params.append("pageNumber", 1);
      params.append("pageSize", 20);

      if (category) params.append("category", category);
      if (tag) params.append("tag", tag);
      if (arrayBoolean.length > 0 && arrayBoolean[0] !== "")
        params.append("booleans", JSON.stringify(arrayBoolean));

      const apiResponse = await axios({
        method: "GET",
        baseURL: BASE_URL,
        url: `/v1/company/profiles?${params.toString()}`,
      });
      dispatch(setTotalPagedCount(apiResponse.data.total_pages));
      dispatch(fetchedProfiles(apiResponse.data.data));
    } catch (error) {
      dispatch(setProfileLoader(false));
      if (error?.response?.status === 401) {
        message.error("Your session has expired. Please log in again!!");
        dispatch(logoutSuccess());
      } else {
        message.error("Error in loading Profiles");
      }
    }
  };

  const getViewedProfilesCount = async (category, tag, boolean) => {
    dispatch(setProfileCountLoader(true));

    const ensureArrayBoolean = (value) => {
      if (Array.isArray(value)) {
        return value; // Already an array, return as is
      }
      if (typeof value === "string") {
        return [value]; // Convert string to array
      }
      return []; // Fallback: if it's neither string nor array, return an empty array
    };

    const arrayBoolean = ensureArrayBoolean(boolean);

    try {
      // Build query parameters dynamically
      const params = new URLSearchParams({
        token: TOKEN,
        isViewed: "true",
      });

      params.append("pageNumber", 1);
      params.append("pageSize", 20);

      if (category) params.append("category", category);
      if (tag) params.append("tag", tag);
      if (arrayBoolean.length > 0 && arrayBoolean[0] !== "")
        params.append("booleans", JSON.stringify(arrayBoolean));

      const apiResponse = await axios({
        method: "GET",
        baseURL: BASE_URL,
        url: `/v1/company/profiles?${params.toString()}`,
      });

      dispatch(setIsViewedProfilesCount(apiResponse.data.total_records));
    } catch (error) {
      dispatch(setProfileCountLoader(true));
      // if (error?.response?.status === 401) {
      //   message.error("Your session has expired. Please log in again!!");
      //   dispatch(logoutSuccess());
      // } else {
      //   message.error("Error in loading viewed profile count");
      // }
    }
  };

  const profilesViewed = async (profile_id, category, tag, boolean) => {
    try {
      const apiResponse = await axios({
        method: "PUT",
        baseURL: BASE_URL,
        url: `/v1/company/profiles/${profile_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `jwt ${TOKEN}`,
        },
        data: {
          category: category,
          tag: tag,
          boolean,
          is_viewed: true,
        },
      });
      dispatch(setIsViewedProfilesCount(isViewedProfileCount + 1));
      // remove the profile from the candidates array whose id === candidata.id;
      const profileAfterRemovingSeenProfile = candidates.filter(
        (profile) => profile.id !== profile_id
      );
      dispatch(fetchedProfiles(profileAfterRemovingSeenProfile));
    } catch (error) {
      // if (error?.response?.status === 401) {
      //   message.error("Your session has expired. Please log in again!!");
      //   dispatch(logoutSuccess());
      // } else {
      //   message.error("Error in moving profile to viewed section");
      // }
    }
  };

  return {
    getLocations,
    getCategory,
    getFreeProfiles,
    getProfiles,
    getSkillCluster,
    getViewedProfilesCount,
    profilesViewed,
    getViewedProfiles,
  };
}

export default useJobsData;
